import styled from 'styled-components';

export const BodyWrap = styled.div`
  width: 66rem;
  max-width: 90%;
  margin: 20rem auto 20rem;
  &:last-of-type {
    margin-bottom: 8rem;
  }
  p {
    margin: 2rem 0;
  }
  a {
    color: white;
    font-size: 2.2rem;
    line-height: 1.7;
    text-decoration: underline;
    @media (max-width: ${(props) => props.theme.breakpoints.tabletLan}) {
      font-size: 1.8rem;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
      font-size: 1.6rem;
    }
  }
  h3 {
    margin: 6rem 0 1rem;
  }
  h4 {
    margin: 6rem 0 1rem;
    font-size: 3.6rem;
  }
  h5 {
    margin: 6rem 0 1rem;
  }
  h2 {
    margin-bottom: 5rem;
    text-align: center;
  }
  tr {
    th:first-of-type {
      min-width: 6rem;
    }
  }
  th {
    p {
      font-weight: bolder;
      text-align: left;
    }
  }
  li {
    p:first-of-type {
      min-width: 4rem;
      max-width: 4rem;
    }
  }
`;
