import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Header, Hero, MobileNav, Footer } from 'components';

import { Container, Content, ChildrenWrap } from './styled';

const MainLayout = ({ children }) => {
  const { contentfulSiteMetadata } = useStaticQuery(query);
  return (
    <Container id='top'>
      <Header socialsData={contentfulSiteMetadata} />

      <MobileNav socialsData={contentfulSiteMetadata} />

      <Content>
        <ChildrenWrap>{children}</ChildrenWrap>
        <Footer socialsData={contentfulSiteMetadata} />
      </Content>
    </Container>
  );
};

const query = graphql`
  query {
    contentfulSiteMetadata {
      instagram
      tikTok
      twitter
      footerText
      buttonText
      disclaimer
    }
  }
`;

export default MainLayout;
