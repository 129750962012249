import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  overflow-x: ${(props) => (props.isFourOhFour ? 'auto' : 'hidden')});
`;

export const Content = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100vh;
  left: 0;
  top: ${(props) => (props.isFourOhFour ? '0' : '100vh')});
  background: linear-gradient(
    180deg,
    #040d2c 9.9%,
    #0c2c6a 20.98%,
    #1e78ff 47.92%,
    #040d2c 88.54%
  );
`;

export const ChildrenWrap = styled.div`
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;
